import React, { useEffect, useState } from "react";
import { useNavigate, useLocation,useSearchParams } from "react-router-dom";
import { animateScroll, scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import {
  setDiscord,
  setEmail,
  setTwitter,
  setWallet,
  setParams,
    setSeqemail,
  setRedeemed,
} from "store/actions/User";
import Storage from "utils/Storage";
import Button from "components/Button";
import Sidebar from "./Sidebar";
import MenuItem from "./MenuItem";
import routes from "./routes";
import Logo from "assets/images/logo.svg";
import Menu from "assets/images/menu.svg";
import User from "assets/images/user.svg";
import API from "apis";
import axios from 'axios';
import { sequence } from "0xsequence";

sequence.initWallet("polygon", { walletAppURL: "https://sequence.app" });

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const wallet = useSelector((state) => state.user.wallet);
  const login_wallet = sequence.getWallet();
  const [searchParams] = useSearchParams();
  
  const { params,email,sequence_email } = useSelector(
    (state) => state.user
  );
  if (!seqemail_lo)
  {var seqemail_lo = 'default';}

 //  console.log("hero marketing code: "+params);
		
	  //window.gtag("set", "campaign_id", params); 
//	  console.log('header loaded');
	//  console.log(sequence_email);
//	  console.log(state.user);
	  
  const handleRedirect = (id) => {
    navigate("/");
    setActiveLink(id);
  };
  
  const openDiscord = () => {
      window.open("https://discord.gg/W2Hmbb9sKp")
    };
	
	const openWhitepaper = () => {
		window.open("https://esportsheroes.gg/whitepaper/");
	};

  useEffect(() => {
    if (activeLink) {
      scroller.scrollTo(activeLink, { smooth: true });
      return;
    }
    animateScroll.scrollToTop();
  }, [activeLink]);

  const getShortAddress = (address) => {
    if (!address) return "";
    return address.slice(0, 5) + "..." + address.slice(-5);
  };
  
    const getShortEmail = (a) => {
    if (!a) return "Log in";
    return a.split('@')[0];
	//return a;
  };
  
    const handleConnect = async () => {
	  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
	axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w=unknown&c='+params+'&e=login_start&t=header');
    const connectDetails = await login_wallet.connect({
		app: "Esports Heroes",
		askForEmail: true,
		settings: {
    theme: "greenDark",
    bannerUrl: "https://esportsheroes.gg/ogh_assets/esh_sequence_banner.jpg",  // 3:1 aspect ratio, 1200x400 works best
	signInOptions: ["google", "discord","facebook","twitch","email"],

  }
		});
	const connected = connectDetails.connected;
	//console.log(connected);

    if (connected) {
		
      const address = await login_wallet.getAddress();
      API.register({ wallet: address, status:"active",email:"true@true.com",  sequence_email: connectDetails.email, params : params,referral_code: params}).then((res) => {
		 // console.log(res);
        dispatch(setDiscord(res.data.discord));
        dispatch(setTwitter(res.data.twitter));
		dispatch(setParams(res.data.params));
		dispatch(setRedeemed(res.data.redeemed));
		dispatch(setSeqemail(connectDetails.email));
		//console.log(connectDetails.email)
        if (res.data.status === "active") {
          dispatch(setEmail(res.data.email));
        }
        dispatch(setWallet(address));
		//console.log(sequence_email);
		axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+address+'&c='+params+'&e=login_complete&t=header');
		window.fbq("trackCustom", "CreateWalletComplete", {campaign_id : params});
		window.gtag("event", "login_complete", {method: "seqeuence",esh_campaign_id : params});
		navigate("/inventory");
		//const { email, discord, twitter, wallet } = useSelector((state) => state.user);
		//window.location.href = '/inventory';
      });

	  
    }
  };


  useEffect(() => {
	  console.log("oneffect");
    const sequenceWalletInfo = Storage.get("@sequence.session");
	//console.log(sequenceWalletInfo);
    if (sequenceWalletInfo) {
      const walletAddress = JSON.parse(
        Storage.get("@sequence.session")
      ).accountAddress;

      dispatch(setWallet(walletAddress));
      if (pathname === "/inventory") {
        API.me({ wallet: walletAddress }).then((res) => {
          if (res.data) {
            dispatch(setDiscord(res.data.discord));
            dispatch(setTwitter(res.data.twitter));
            dispatch(setParams(res.data.params));
			dispatch(setSeqemail(res.data.sequence_email));
			seqemail_lo = res.data.sequence_email;
			//console.log('in inv switch');
			//console.log(res);
			//console.log(seqemail_lo);
			//console.log(res.data.sequence_email);
			dispatch(setRedeemed(res.data.redeemed));
            if (res.data.status === "active") {
              dispatch(setEmail(res.data.email));
            }
			axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+walletAddress+'&c='+res.data.params+'&e=set_campaign&t=header_useeffect_loggedin_home');
          }
        });
      } else {
		API.me({ wallet: walletAddress }).then((res) => {
          if (res.data) {
            dispatch(setDiscord(res.data.discord));
            dispatch(setTwitter(res.data.twitter));
			dispatch(setParams(res.data.params));
			dispatch(setSeqemail(res.data.sequence_email));
			seqemail_lo = res.data.sequence_email;
						console.log('in redir switch');
			//console.log(seqemail_lo);
			/////////////////////console.log(res.data.sequence_email);
						//console.log(res);
			dispatch(setRedeemed(res.data.redeemed));
            if (res.data.status === "active") {
              dispatch(setEmail(res.data.email));
            }
          axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+walletAddress+'&c='+res.data.params+'&e=set_campaign&t=header_useeffect_loggedin_redir');
		  }
        });
		
      navigate("/inventory");
       
      }
	 //console.log("logged in:");
	  //console.log(JSON.parse(Storage.get("@sequence.session")));
	  //console.log(seqemail_lo);
    } else {
		  try {
	  var cid = searchParams.get("cid").replace(/\D/g,'').slice(0, 5);
	  dispatch(setParams(cid));
  } catch (err) {
	  //console.log(err);
	  dispatch(setParams(0));
	  var cid = 0
    } 
	//console.log("marketing id: "+cid);
	//console.log("not loggedin in");
	axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w=unknown&c='+cid+'&e=set_campaign&t=header_useeffect_notloggedin');
	navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="flex items-center justify-between fixed z-[1000] bg-black top-0 left-0 right-0 h-20 xl:h-25 px-6">
        <img
          src={Logo}
          onClick={() => handleRedirect(null)}
          className="h-16 xl:h-auto cursor-pointer"
          alt="logo"
        />
        <ul className="h-full hidden xl:flex items-center">
          {routes.map((route) => (
            <li key={route.id} className="h-full mx-2 xl:mx-[18px] text-sm text-gray-400 hover:text-font-gray">
              <MenuItem
                {...route}
                active={route.id === activeLink}
                onClick={handleRedirect}
              />
            </li>
          ))}
        </ul>
        <div className="flex items-center">
          <div className="w-auto lg:w-44">
            {wallet && (
              <div className="flex items-center justify-end pr-0 sm:pr-[30px]">
                <img src={User} alt="user" />
                <span className="font-bold text-light uppercase ml-2" onClick={() => {navigate("/inventory");}}>
					  {getShortEmail(sequence_email)}
				  
                </span>

              </div>
            )}
			{(!wallet) && (
			              <div className="flex items-center justify-end pr-0 sm:pr-[30px]">
                <img src={User} alt="user" />
                <span className="font-bold text-light uppercase ml-2" onClick={handleConnect}>
					Log in
				  
                </span>

              </div>
			)}
          </div>
          <div className="hidden sm:inline-block">
		  
            <Button className="w-[150px]" onClick={openDiscord}>Discord</Button>
			
            <Button className="w-[150px] ml-3" variant="outlined" onClick={openWhitepaper}>
              Whitepaper
            </Button>
          </div>
          <img
            src={Menu}
            className="flex xl:hidden cursor-pointer ml-4 hover:opacity-70"
            onClick={handleOpen}
            alt="menu"
          />
        </div>
      </div>
      <Sidebar open={isOpen} onClose={handleClose} />
    </>
  );
};

export default Header;
