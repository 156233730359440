import React, { useEffect, useState } from "react";
import { sequence } from "0xsequence";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,useSearchParams } from "react-router-dom";
import {
  setDiscord,
  setEmail,
  setTwitter,
  setWallet,
  setParams,
  setSeqemail,
  setRedeemed,
  setReferral,
} from "store/actions/User";
import YouTube from "react-youtube";
import Button from "components/Button";
import Heros from "assets/images/heros.png";
import API from "apis";
import axios from 'axios';

sequence.initWallet("polygon", { walletAppURL: "https://sequence.app" });

	  console.log('hero loaded');



const Hero = () => {
	  const [searchParams] = useSearchParams();
const { params,sequence_email } = useSelector(
    (state) => state.user
  );
  
  
  
 //  console.log("hero marketing code: "+params);
	  //window.gtag("set", "campaign_id", params); 
	 // console.log('gtag cid set params hero: '+params);
  

  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState({
    remaining: 0,
    date: null,
  });
  const wallet = sequence.getWallet();
  const navigate = useNavigate();
  const opts = {
    width: "640",
    height: "390",
    playerVars: {
      autoplay: 1,
    },
  };
  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };
  
  




  const handleConnect = async () => {
	  // eslint-disable-next-line @typescript-eslint/no-unused-expressions

    const connectDetails = await wallet.connect({
		app: "Esports Heroes",
		askForEmail: true,
		settings: {
    theme: "greenDark",
    bannerUrl: "https://esportsheroes.gg/ogh_assets/esh_sequence_banner.jpg",  // 3:1 aspect ratio, 1200x400 works best
	signInOptions: ["email", "google", "discord","facebook","twitch"],

  }
		});
	const connected = connectDetails.connected

    if (connected) {
      const address = await wallet.getAddress();
      API.register({ wallet: address, status:"active",email:"true@true.com",  sequence_email: connectDetails.email, params : params, referral_code: params}).then((res) => {
        dispatch(setDiscord(res.data.discord));
        dispatch(setTwitter(res.data.twitter));
		dispatch(setParams(res.data.params));
		dispatch(setRedeemed(res.data.redeemed));
		dispatch(setSeqemail(connectDetails.email));
		//console.log(connectDetails.email)
        if (res.data.status === "active") {
          dispatch(setEmail(res.data.email));
        }
        dispatch(setWallet(address));
		//console.log(sequence_email);
		axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+address+'&c='+params+'&e=login_complete&t=hero');
		window.fbq("trackCustom", "CreateWalletComplete", {campaign_id : params});
		window.gtag("event", "login_complete", {method: "seqeuence",esh_campaign_id : params});
		navigate("/inventory");
		//const { email, discord, twitter, wallet } = useSelector((state) => state.user);
		//window.location.href = '/inventory';
      });

	  
    }
  };
  
      

  useEffect(() => {
    API.getCountdown().then((res) => {
      if (res.data.length > 0) {
        const data = res.data[0];
        setCountdown({
          remaining: data.remaining,
          total: data.total,
          remaining_days: data.remaining_days,
          date: data.date.slice(0, 10),
        });
      }
    });
		 //axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+wallet+'&c='+params+'&e=set_campaign&t=hero_load');
  }, []);
  
  useEffect(() => {
	  //try {var blub = searchParams.get("cid").replace(/\D/g,'').slice(0, 5);} catch {var blub = 0;}
	  //window.gtag("set", "campaign_id", blub);
	  //console.log('inv gtag cid set 2: '+blub);
	  //window.gtag("set", "campaign_id", params); 
	  //console.log('gtag cid set params: '+params);
	  
	  
  }, []);
  


  return (
    <div className="px-6 relative overflow-hidden bg-main">
      <img
        src={Heros}
        className="w-full md:w-2/3 xl:w-auto absolute -bottom-5 left-0"
        alt="heros"
      />
      <div className="w-full max-w-8xl mx-auto flex relative z-10 flex-col xl:flex-row gap-1">
        <div className="w-full xl:flex items-center justify-center">
		      <iframe style={{"aspect-ratio": "16 / 9"}} className="border-[2px] w-full border-lightgreen border-solid mx-auto" src="https://www.youtube.com/embed/nCYifiIAMOo?rel=0&autoplay=1;showinfo=0&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className="w-full xl:w-[698px] flex-shrink-0">
          <div className="w-full flex flex-col items-center xl:items-end py-20 lg:pt-35 lg:pb-28">
            <div className="mb-5 font-bold text-5xl lg:text-[120px] leading-8 lg:leading-[80px] uppercase text-right">
              <p className="text-transparent text-stroke-2 text-stroke-success opacity-30 pr-10 lg:pr-30 mb-5">
                Get your
              </p>
              <p className="text-light">free Pro!</p>
            </div>
            <p className="uppercase font-semibold text-xl lg:text-[28px] text-success leading-6 lg:leading-[34px] mb-5 lg:mb-8 text-left">
			Only {countdown.remaining}/{countdown.total} free pros left!
            </p>
            <p className="text-xl lg:text-[28px] leading-6 lg:leading-[34px] text-center xl:text-right mb-10 lg:mb-16 text-light">
               Esports Heroes is the first mobile idle RPG in the world of esports. Pre-register now to get a head start at launch with free exclusive content.<br />
              Live the life of a pro and your passion for esports!
            </p>
			
            <Button className="w-45" size="lg" onClick={() => {window.fbq("trackCustom", "CreateWallet", {campaign_id : params});
	  window.gtag("event", "login_start", {method: "seqeuence",esh_campaign_id : params});
	  axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+wallet+'&c='+params+'&e=login_start&t=hero');
	  handleConnect();}}>
              Get your free pro!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
