import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { animateScroll, scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button";
import routes from "./routes";
import Logo from "assets/images/logo.svg";
import Close from "assets/images/close.svg";
import MenuItem from "./MenuItem";

const Sidebar = ({ open, onClose }) => {
	  const { pathname } = useLocation();
	    const [isOpen, setIsOpen] = useState(false);
	  const navigate = useNavigate();
	  const [activeLink, setActiveLink] = useState(null);
    const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

const handleRedirect = (id) => {
    navigate("/");
    setActiveLink(id);
	onClose();
	  };
	
  useEffect(() => {
    document.body.style.overflowY = open ? "hidden" : "auto";
	    if (activeLink) {
      scroller.scrollTo(activeLink, { smooth: true });
      return;
    }
    animateScroll.scrollToTop();
  }, [open,activeLink]);
  


  


  return (
    <>
      {open && (
        <div
          className="fixed w-full h-screen top-0 left-0 z-[1200]"
          onClick={onClose}
        ></div>
      )}
      <div
        className={`fixed top-0 left-0 w-80 h-screen bg-black z-[1200] border-r border-r-gray-700 transition ${
          open ? `translate-x-0` : `-translate-x-full`
        }`}
      >
        <img
          src={Close}
          className="cursor-pointer absolute top-5 right-5"
          alt="close"
          onClick={onClose}
        />
        <img src={Logo} className="mx-auto my-5" alt="logo" />
        <ul className="w-full px-5">
          {routes.map((route) => (
            <li
              key={route.id}
              className="mb-2 last:mb-0 uppercase relative w-full h-10 flex justify-center items-center text-light bg-success clip-button-outline before:absolute before:w-full before:h-full before:bg-dark before:top-0 before:left-0 before:clip-button-inline">

			  <MenuItem
                {...route}
                active={route.id === activeLink}
                onClick={handleRedirect}
              />
            </li>
          ))}
        </ul>
        <div className="absolute left-5 right-5 bottom-5 flex flex-col items-center">
          <Button containerClassName="w-full" className="mb-2" fullWidth>
            Discord
          </Button>
          <Button
            containerClassName="w-full"
            className="mb-4"
            variant="outlined"
            fullWidth
          >
            Whitepaper
          </Button>
          <p>Legendary Play © 2022</p>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
