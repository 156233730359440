const routes = [
  { id: "root", label: "Trailer" },
  { id: "screens", label: "The Game" },
//  { id: "gameplay", label: "Gameplay" },
  { id: "gear", label: "You're the star" },
  { id: "web3", label: "Play & Own" },
  { id: "roadmap", label: "Roadmap" },
  { id: "team", label: "Our Team" },
  { id: "partners", label: "Friends & Partners" },
];

export default routes;
