import React from 'react';

import { ReactComponent as Twitter } from 'assets/images/social/twitter.svg';
import { ReactComponent as Facebook } from 'assets/images/social/facebook.svg';
import { ReactComponent as Youtube } from 'assets/images/social/youtube.svg';
import { ReactComponent as Zoomietube } from 'assets/images/social/zoomietube.svg';
import { ReactComponent as Discord } from 'assets/images/social/discord.svg';

const Footer = () => {
  return <div className='flex flex-col items-center font-roboto bg-success p-11 pb-9 text-black'>
    <ul className='flex mb-1.5'>
      <li className='w-15 h-15 flex items-center justify-center mx-0 md:mx-2.5'>

	 <a href="https://twitter.com/EsportsHeroesGG" target="_blank"><img class="max-h-18" src="assets/images/social/twitter.svg" alt=""/>
		<span class="sr-only">Twitter</span>	
        <Twitter />
		</a>
      </li>
	  
	  
      <li className='w-15 h-15 flex items-center justify-center mx-0 md:mx-2.5'> 
		<a href="https://facebook.com/Playesportsheroes" target="_blank"><img class="max-h-18" src="assets/images/social/facebook.svg" alt=""/>
		<span class="sr-only">Facebook</span>
		<Facebook />
		</a>
      </li>
	  
	  
      <li className='w-15 h-15 flex items-center justify-center mx-0 md:mx-2.5'>
        <a href="https://www.youtube.com/watch?v=nCYifiIAMOo" target="_blank"><img class="max-h-18" src="assets/images/social/youtube.svg" alt=""/>
		<span class="sr-only">Youtube</span>
		<Youtube />
      </a>
	  </li>
	  
	  
      <li className='w-15 h-15 flex items-center justify-center mx-0 md:mx-2.5'>
        <a href="https://discord.gg/W2Hmbb9sKp" target="_blank"><img class="max-h-18" src="assets/images/social/discord.svg" alt=""/>
		<span class="sr-only">Discord</span>
		<Discord />
		</a>
      </li>
	  
	  
    </ul>
     
	 <p className='text-sm mb-5 md:mb-0'>
	  <a href="https://legendaryplay.gg" target="_blank">Legendary Play GmbH - (C) 2022</a></p>
	  
	
	
    <ul className='flex flex-col md:flex-row items-center text-sm'>
	
      <li className='mx-1.5'>
	  <a href="https://legendaryplay.gg/privacy" target="_blank"><img class="max-h-18"/>Privacy Policy</a></li>
	  
 
 
      <li className='mx-1.5'>
	   <a href="https://legendaryplay.gg/tos" target="_blank"><img class="max-h-18"/>Terms & Conditions</a></li>
	  
      <li className='mx-1.5'>
	   <a href="https://legendaryplay.gg/impressum" target="_blank"><img class="max-h-18"/>Contact & Impressum</a></li>
	  
    </ul>
  </div>
}

export default Footer;