import {
  SET_AUTH,
  SET_WALLET,
  SET_EMAIL,
  SET_DISCORD,
  SET_TWITTER,
  SET_PARAMS,
  SET_SEQEMAIL,
  SET_REDEEMED,
  SET_REFERRAL,
} from "../type";

export const setAuth = (data) => async (dispatch) => {
  dispatch({ type: SET_AUTH, payload: data });
};

export const setWallet = (data) => async (dispatch) => {
  dispatch({ type: SET_WALLET, payload: data });
};

export const setEmail = (data) => async (dispatch) => {
  dispatch({ type: SET_EMAIL, payload: data });
};

export const setDiscord = (data) => async (dispatch) => {
  dispatch({ type: SET_DISCORD, payload: data });
};

export const setTwitter = (data) => async (dispatch) => {
  dispatch({ type: SET_TWITTER, payload: data });
};

export const setParams = (data) => async (dispatch) => {
  dispatch({ type: SET_PARAMS, payload: data });
};

export const setSeqemail = (data) => async (dispatch) => {
  dispatch({ type: SET_SEQEMAIL, payload: data });
};

export const setReferral = (data) => async (dispatch) => {
  dispatch({ type: SET_REFERRAL, payload: data });
};

export const setRedeemed = (data) => async (dispatch) => {
  dispatch({ type: SET_REDEEMED, payload: data });
};
