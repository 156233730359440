import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDiscord, setTwitter, setParams, setRedeemed, setSeqemail } from "store/actions/User";
import SignUpNewsLetterModal from "components/containers/Inventory/SignUpNewsLetterModal";
import Button from "components/Button";
import CheckBox from "components/CheckBox";
import API from "apis";
import Hero from "assets/images/hero.png";
import HeroShadow from "assets/images/hero-shadow.png";
import axios from 'axios';
import TextField from "components/TextField";



const Inventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [hero, setHero] = useState(false);
  const [referral_code, setReferral] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { email, discord, twitter, wallet, params,sequence_email,redeemed } = useSelector(
    (state) => state.user
  );


	  useEffect(() => {
 //  console.log("hero marketing code: "+params);
	  //window.gtag("set", "campaign_id", params); 
	  //console.log('gtag cid set params inventory: '+params);
	  axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+wallet+'&c='+params+'&e=set_campaign&t=inventory_load');
	  	  console.log('inv loaded');
		  	  }, []);
		  
  const handleGetHero = () => {
    setHero(true);
	handleConnectRedeemed();
	//API.updateUserReferral({ referral_code: referral_code, wallet }).then(() => {console.log('referral_code set');});
  };
  
 //console.log("inventory marketing code: "+params);
 //	  window.gtag("set", "campaign_id", params); 
	//  console.log('gtag cid set params load: '+params);
 
// WHEN REENABLING REFERRAL CODE SYSTEM, REIMPLEMENT FOLLOWING CODE BLOCK ABOVE SUBMISSION BUTTON
//				<div className="flex items-center mb-5 lg:mb-7" >
 //                 <TextField
  //          value={referral_code}
   //         onChange={handleChange}
     //       placeholder="OPTIONAL REFERRAL CODE"
       //   />
         //       </div>


  const handleConnectTwitter = () => {
    API.updateUserTwitter({ twitter: true, wallet }).then(() => {
      dispatch(setTwitter(true));
      window.open("https://twitter.com/EsportsHeroesGG");
	  window.fbq("trackCustom", "CompleteTwitter", {campaign_id : params});
	  window.gtag("event", "complete_twitter", {level_name: "Twitter",esh_campaign_id : params});
	  axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+wallet+'&c='+params+'&e=twitter_click&t=inventory');
	  //console.log('twitter click');	  
    });
  };

  const handleConnectDiscord = () => {
    API.updateUserDiscord({ discord: true, wallet }).then(() => {
      dispatch(setDiscord(true));
	  	  	  window.fbq("trackCustom", "CompleteDiscord", {campaign_id : params});
	  window.gtag("event", "complete_discord", {level_name: "discord",esh_campaign_id : params});
	  //console.log('discord click');	
	  axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+wallet+'&c='+params+'&e=discord_click&t=inventory');
	  	  switch (params) {
		  case "16":
			window.open("https://discord.gg/K5aHhz5fWq");
			break;
		  case "17":
			window.open("https://discord.gg/63m5FjFQJt");
			break;
		  case "18":
			window.open("https://discord.gg/ePpcJbBYKH");
			break;
		  case "19":
			window.open("https://discord.gg/6y2E7HKyMK");
			break;
		  case "20":
			window.open("https://discord.gg/p5KTdFzAYV");
			break;
		  case "21":
			window.open("https://discord.gg/Bg2pXcUDwV");
			break;
		  case "22":
			window.open("https://discord.gg/EqZ6r3ER2U");
			break;
		  case "23":
			window.open("https://discord.gg/pfwaJM88nv");
			break;
		  case "24":
			window.open("https://discord.gg/vM8GAgkMRx");
			break;
		  case "25":
			window.open("https://discord.gg/NZxruFavM4");
			break;
		  case "26":
			window.open("https://discord.gg/78abqg3J9b");
			break;
		  case "27":
			window.open("https://discord.gg/vJnf65pMKS");
			break;
		  case "28":
			window.open("https://discord.gg/duESZjH2vn");
			break;
		  case "29":
			window.open("https://discord.gg/sVTReFQu8p");
			break;
		  case "30":
			window.open("https://discord.gg/PmkkvXYqSh");
			break;
		  case "31":
			window.open("https://discord.gg/P2p8sDMaJK");
			break;
		case "32":
			window.open("https://discord.gg/EwuSgS3CN2");
			break;
		  default:
			window.open("https://discord.gg/W2Hmbb9sKp");
					}
      
    });
  };
  
  const openDiscord = () => {
      switch (params) {
		  case "16":
			window.open("https://discord.gg/K5aHhz5fWq");
			break;
		  case "17":
			window.open("https://discord.gg/63m5FjFQJt");
			break;
		  case "18":
			window.open("https://discord.gg/ePpcJbBYKH");
			break;
		  case "19":
			window.open("https://discord.gg/6y2E7HKyMK");
			break;
		  case "20":
			window.open("https://discord.gg/p5KTdFzAYV");
			break;
		  case "21":
			window.open("https://discord.gg/Bg2pXcUDwV");
			break;
		  case "22":
			window.open("https://discord.gg/EqZ6r3ER2U");
			break;
		  case "23":
			window.open("https://discord.gg/pfwaJM88nv");
			break;
		  case "24":
			window.open("https://discord.gg/vM8GAgkMRx");
			break;
		  case "25":
			window.open("https://discord.gg/NZxruFavM4");
			break;
		  case "26":
			window.open("https://discord.gg/78abqg3J9b");
			break;
		  case "27":
			window.open("https://discord.gg/vJnf65pMKS");
			break;
		  case "28":
			window.open("https://discord.gg/duESZjH2vn");
			break;
		  case "29":
			window.open("https://discord.gg/sVTReFQu8p");
			break;
		  case "30":
			window.open("https://discord.gg/PmkkvXYqSh");
			break;
		  case "31":
			window.open("https://discord.gg/P2p8sDMaJK");
			break;
		  default:
			window.open("https://discord.gg/W2Hmbb9sKp");
					}
    };

  const handleConnectParams = () => {
    API.updateUserParams({ params: true, wallet }).then(() => {
      dispatch(setParams(true));
    });
  };

  const handleChange = (e) => {
    setReferral(e.target.value);
  };
  
  const handleConnectRedeemed = () => {
    API.updateUserRedeemed({ redeemed: true, wallet }).then(() => {
      dispatch(setRedeemed(true));
	  	  	  window.fbq("trackCustom", "CompleteRedeem", {campaign_id : params});
	  window.gtag("event", "complete_redeem", {level_name: "redeem",esh_campaign_id : params});
	  axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+wallet+'&c='+params+'&e=redeem&t=inventory');
	  //console.log('redeem click');	
    });
  };

//dial back discord stuff
{/*
  const handleConnectDiscord = () => {
    window.open(
      "https://discord.com/api/oauth2/authorize?client_id=1028004377769820190&permissions=1&redirect_uri=https%3A%2F%2Ffree.esportsheroes.gg%2Finventory&response_type=code&scope=email%20identify%20guilds%20guilds.join"
    );
  };




    // eslint-disable-next-line react-hooks/exhaustive-deps
    */} 
	
  
	  useEffect(() => {
    if (code && wallet) {
      API.addDiscordGuildMember(code).then((res) => {
        const { email, status } = res.data;
        if (status) {
          API.updateUserDiscord({
            discord: email,
            wallet,
          }).then(() => {
            dispatch(setDiscord(email));
            navigate("/inventory");
          });
        }
      });
    }
	  }, [wallet]);

  return (
    <div className="px-6 py-20 xl:py-30">
      <div className="flex flex-col xl:flex-row w-full max-w-8xl mx-auto">
        <div className="w-full">
          <div className="w-full max-w-full xl:max-w-[640px] flex flex-col items-center xl:items-start">
            <div className="font-bold text-5xl lg:text-[120px] leading-8 lg:leading-[80px] uppercase text-center mb-10 lg:mb-18">
              <p className="text-transparent text-stroke-2 text-stroke-success opacity-30 mb-5">

                {hero || redeemed ? `Locked` : `Almost`}
              </p>
              <p className="text-light">{hero || redeemed ? `& Loaded` : `There`}</p>

			  <p></p>


            </div>
			{hero || redeemed ? (
            <p className="text-xl lg:text-[28px] leading-6 lg:leading-[34px] mb-10 lg:mb-11 text-center xl:text-left">
              <b>You are in!</b> Once <b>Esports Heroes</b> launches, we will send you an email with instructions on how to redeem your free pro and start playing! We are excited to bring you along on the quest to build ESH for you esports fans.<br />
              <br />
               Want to be among the first to test development versions of <b>Esports Heroes</b>? We run events and giveaways for alpha and beta slots on Discord... Explore our server now and never miss an update!
            </p>
			) : (
			  <p className="text-xl lg:text-[28px] leading-6 lg:leading-[34px] mb-10 lg:mb-11 text-center xl:text-left">
              Welcome to the pre-registration! With your free Hero, you will be able to dive right into pro esports without having to grind your way up through the ranks of wannabes!<br />
              <br />
              We only ask you to join our community on Discord and Twitter to keep up with news, updates and reveals around <b>Esports Heroes</b>. That is really all it takes to reserve your spot at the head of the line. LFG!
            </p>
			)}
            {hero || redeemed ? (
              <Button className="w-[246px]" size="lg" 
			  onClick={() => {openDiscord();window.fbq("trackCustom", "RedeemedDiscord", {campaign_id : params}); window.gtag("event", "redeem_discord", {level_name: "RedeemedDiscord",esh_campaign_id : params});axios.get('https://1701.esportsheroes.gg:9000/subspace_comms?w='+wallet+'&c='+params+'&e=discord_redeem_click&t=inventory');}}>
                Visit Discord!
              </Button>
            ) : (
              <div className="uppercase font-semibold text-xl lg:text-[28px] leading-6 lg:leading-[34px]">
                <div className="flex items-center mb-5 lg:mb-7" onClick={handleConnectDiscord}>
                  <CheckBox size="lg" checked={discord} />
                  <span
                    className="cursor-pointer transition hover:opacity-70 ml-3 lg:ml-5 text-success"
                    
                  >
                    Join Discord
                  </span>
                </div>
                <div className="flex items-center mb-5 lg:mb-7" onClick={handleConnectTwitter}>
                  <CheckBox size="lg" checked={twitter} />
                  <span
                    className="cursor-pointer transition hover:opacity-70 ml-3 lg:ml-5 text-success"
					
                  >
                    Follow Twitter
                  </span>
                </div>
				

				
								<div className="flex items-center mb-5 lg:mb-7" >
					                  <Button
                    className="w-[180px]"
                    size="lg"
					onClick={handleGetHero}
                    animation={twitter && discord && email}
                    disabled={!(twitter && discord && email)}
                  >
                    Get Now!
                  </Button>
                </div>
                
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center xl:justify-end items-start pt-12">
          <div className="relative">
            {hero || redeemed ? (
              <img src={Hero} className="w-[455px]" alt=":( Not Found" />
            ) : (
              <>
                <img
                  src={HeroShadow}
                  className="w-[455px]"
                  alt=":( Not Found"
                />
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">

                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <SignUpNewsLetterModal open={isOpen} onClose={() => setIsOpen(false)} />
    </div>

  );
};

export default Inventory;
