import React from "react";
import { Element } from "react-scroll";
import Title from "components/Title";
import Partner from "assets/images/esl.png";
import HelperText from "components/HelperText";

const Partners = () => {
  return (
    <Element name="partners">
      <div className="px-6 py-20 xl:pt-0 xl:pb-45 relative">
        <HelperText align="right">-Legendary Play-</HelperText>
        <div className="w-full flex flex-col items-center xl:items-start max-w-8xl mx-auto">
          <Title className="mb-24 xl:mb-[220px]" title="Partners" />
          <div className="w-auto xl:w-full flex -m-2 md:-m-5 xl:m-0">
            

			<div class="col-span-1 flex justify-center py-8 px-8">

			  <a href="https://www.blockchaingamealliance.org/" target="_blank"><img class="max-h-18" src="http://esportsheroes.gg/assets/partner/bga.png" alt="bga"/></a>

			</div>
			<div class="col-span-1 flex justify-center py-8 px-8">

			  <a href="https://www.bitkraft.vc/" target="_blank"><img class="max-h-18" src="http://esportsheroes.gg/assets/partner/bk.png" alt="BITKRAFT"/></a>

			</div>

			<div class="col-span-1 flex justify-center py-8 px-8">

			  <a href="https://1upfund.com/" target="_blank"><img class="max-h-18" src="http://esportsheroes.gg/assets/partner/1up.png" alt="1Up Ventures"/></a>

			</div>
		

			<div class="col-span-1 flex justify-center py-8 px-8">

			  <a href="https://lvl.global/" target="_blank"><img class="max-h-18" src="http://esportsheroes.gg/assets/partner/lvl.png" alt="LVL"/></a>

			</div>

			<div class="col-span-1 flex justify-center py-8 px-8">

			  <a href="https://nefta.io/" target="_blank"><img class="max-h-18" src="http://esportsheroes.gg/assets/partner/nefta.png" alt="nefta.io"/></a>

			</div>
			
			<div class="col-span-1 flex justify-center py-8 px-8">

			  <a href="https://nami.gg/" target="_blank"><img class="max-h-18" src="http://esportsheroes.gg/assets/partner/nami.png" alt="nami.gg"/></a>

			</div>

			<div class="col-span-1 flex justify-center py-8 px-8">

			  <a href="https://unboundnation.io" target="_blank"><img class="max-h-18" src="http://esportsheroes.gg/assets/partner/unbound.png" alt="Unbound nation"/></a>

			</div>


		  </div>
        </div>
      </div>
    </Element>
  );
};

export default Partners;
