import {
  SET_AUTH,
  SET_WALLET,
  SET_EMAIL,
  SET_DISCORD,
  SET_TWITTER,
  SET_PARAMS,
  SET_SEQEMAIL,
  SET_REDEEMED,
  SET_REFERRAL,
} from "../type";

const initialState = {
  auth: false,
  wallet: null,
  email: null,
  discord: null,
  twitter: null,
  params: null,
  sequence_email: null,
  redeemed: null,
  referral_code: null,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return { ...state, auth: action.payload };
    case SET_WALLET:
      return { ...state, wallet: action.payload };
    case SET_EMAIL:
      return { ...state, email: action.payload };
    case SET_DISCORD:
      return { ...state, discord: action.payload };
    case SET_TWITTER:
      return { ...state, twitter: action.payload };
    case SET_PARAMS:
      return { ...state, params: action.payload };
	case SET_REDEEMED:
      return { ...state, redeemed: action.payload };
	case SET_SEQEMAIL:
      return { ...state, sequence_email: action.payload };
	case SET_REFERRAL:
      return { ...state, referral_code: action.payload };
    default:
      return state;
  }
};

export default UserReducer;
